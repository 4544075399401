.survey-list{
    width: "100%";
    background-color: #FFFFFF;
}
.inline {
    display: inline;
    font-size: 12px !important;
    float: right;
}
.connected {
    box-shadow: 0px 0px 6px #009000;
    margin-left: 10px !important;
    margin-right: 10px !important;
}
.not-connected {
    box-shadow: 0px 0px 6px #ce0000;
    margin-left: 10px !important;
    margin-right: 10px !important;
}
