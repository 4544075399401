.status-table{
    width: "100%";
    background-color: #FFFFFF;
}

.goodmessage {
    color: #000000 !important;
}
.badmessage {
    color: #ce0000 !important;
}
.neutralmessage {
    color: #cc8503 !important;
}
