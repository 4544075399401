.list-item:hover {
    background-color: #e4e4e4;
}
.good {
    fill: #009000 !important;
    font-size: 20px !important;
}
.bad {
    fill: #ce0000 !important;
    font-size: 20px !important;
}
.neutral {
    fill: #cc8503 !important;
    font-size: 20px !important;
}